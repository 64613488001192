import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../../../components/Button';
import Layout from '../../../components/Layout';
import renderContent from '../../../utils/rich-text-helpers';
import s from './Salesforce.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array.isRequired,
    }),
  }).isRequired,
  icon: PropTypes.shape({
    fluid: PropTypes.shape({}),
  }).isRequired,
  salesforceSections: PropTypes.arrayOf(
    PropTypes.shape({
      listDescription: PropTypes.shape({
        listDescription: PropTypes.string,
      }),
      listTitle: PropTypes.string,
    }),
  ).isRequired,
};

const ServicesSalesforce = ({ title, icon, description, salesforceSections }) => {
  return (
    <Layout currentPage="/services/salesforce">
      {/* Title Header */}
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title} key={title}>
              <h2 data-test="title">{title}</h2>
            </div>
            {icon?.fluid && (
              <div className={s.image} data-test="salesforce-icon">
                <Img className={s.emoji} fluid={icon.fluid} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={s.pageContainer} data-test="pageContainer">
        {/* Description */}
        {description?.json && (
          <div className={s.pageContent} data-test="description">
            <div className={s.content}>
              {description?.json?.content.map((content) => renderContent(content, s.paragraph))}
            </div>
            <div>
              <Link to="/work">
                <Button theme="grey">See Our Work</Button>
              </Link>
            </div>
          </div>
        )}

        {/* Salesforce Sections */}
        <div className={s.salesforceSectionsContainer}>
          {salesforceSections.map((salesforceSection) => (
            <div key={salesforceSection.listTitle} data-test="salesforceSections">
              <h2>{salesforceSection.listTitle}</h2>
              <p>{salesforceSection.listDescription.listDescription}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

ServicesSalesforce.propTypes = propTypes;
export default ServicesSalesforce;
