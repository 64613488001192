import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ServicesSalesforce from '../../containers/Services/Salesforce';

const propTypes = {
  data: PropTypes.shape({
    salesforce: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.shape({
        json: PropTypes.shape({
          content: PropTypes.array,
          nodeType: PropTypes.string,
        }),
      }).isRequired,
      icon: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }).isRequired,
      salesforceSections: PropTypes.arrayOf(
        PropTypes.shape({
          listDescription: PropTypes.shape({
            listDescription: PropTypes.string,
          }),
          listTitle: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

const SalesforcePage = ({ data }) => {
  return (
    <ServicesSalesforce
      title={data.salesforce.title}
      icon={data.salesforce.icon}
      description={data.salesforce.description}
      salesforceSections={data.salesforce.salesforceSections}
    />
  );
};

SalesforcePage.propTypes = propTypes;
export default SalesforcePage;

export const query = graphql`
  query {
    salesforce: contentfulSalesforce {
      title
      description {
        json
      }
      icon {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      salesforceSections {
        listDescription {
          listDescription
        }
        listTitle
      }
    }
  }
`;
